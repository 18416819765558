import React from 'react'

function About() {
    
  return (
    <div id='main'>
    

    <section id="about" className="about">
      <div className="container">

        <div className="section-title">
          <h2>About</h2>
          <p>Enthusiastic and motivated Computer Science graduate with a strong foundation in web development and a passion for building dynamic user interfaces using React JS. Seeking an entry-level position to apply my skills and contribute to innovative projects.</p>
        </div>

        <div className="row">
          <div className="col-lg-4" data-aos="fade-right">
            <img src="assets/img/hero.jpeg" className="img-fluid" alt="" style={{maxHeight:'300px'}}/>
          </div>
          <div className="col-lg-8 pt-4 pt-lg-0 content" data-aos="fade-left">
            <h3>Front End Developer</h3>
            <p className="fst-italic">
            I want to seek challenging assignments and responsibility
            with an opportunity for growth and career advancement
            as a successfully achievement.
            </p>
            <div className="row">
              <div className="col-lg-6" style={{marginTop:'20px'}}>
                <ul>
                  <li><i className="bi bi-chevron-right"></i> <strong>Birthday:</strong> <span>4 March 1996</span></li>
                  <li><i className="bi bi-chevron-right"></i> <strong>Website:</strong> <span>jaydeep-portfolio.pages.dev</span></li>
                  <li><i className="bi bi-chevron-right"></i> <strong>Phone:</strong> <span>+91 8077115322</span></li>
                  <li><i className="bi bi-chevron-right"></i> <strong>City:</strong> <span>New Delhi, India</span></li>
                </ul>
              </div>
              <div className="col-lg-6" style={{marginTop:'20px'}}>
                <ul>
                  <li><i className="bi bi-chevron-right"></i> <strong>Age:</strong> <span>25</span></li>
                  <li><i className="bi bi-chevron-right"></i> <strong>Degree:</strong> <span>MCA</span></li>
                  <li><i className="bi bi-chevron-right"></i> <strong>Email:</strong> <span>jaydeepyadav.jcmst@gmail.com</span></li>
                  {/* <li><i className="bi bi-chevron-right"></i> <strong>Freelance:</strong> <span>Available</span></li> */}
                </ul>
              </div>
            </div>
          
          </div>
        </div>

      </div>
    </section>
   
    </div>
  )
}

export default About
