import React from 'react'

function Footer() {
  return (
    <div id='main'>
    <footer id="footer">
    <div className="container">
      <div className="copyright">
        &copy; Copyright <strong><span></span></strong>
      </div>
      <div className="credits">
        
        Designed by <a href="#">JD</a>
      </div>
    </div>
  </footer>
  <a href="#" className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a>

    </div>
  )
}

export default Footer
