import React from 'react'


function Header() {
  return (
    <div>

    {/* <i className="bi bi-list mobile-nav-toggle d-xl-none"></i> */}
  
 
    <header id="header">
      <div className="d-flex flex-column">
  
        <div className="profile">
          <img src="assets/img/hero.jpeg" style={{height:'150px',width:'150px'}} alt="" className="img-fluid rounded-circle"/>
          <h1 className="text-light"><a href="index.html">Jaydeep</a></h1>
          <div className="social-links mt-3 text-center">
            <a href="https://www.facebook.com/people/Jaydeep-Yadav/pfbid02doG83dRRn5FrkHM2Ww58xZLyZB2z2DqCgrE7YgaHCRf21spbVRZiGwVFivpcnjpQl/" className="facebook"><i className="bx bxl-facebook"></i></a>
            <a href="https://www.instagram.com/jaydeepyadav__jd__178/" className="instagram"><i className="bx bxl-instagram"></i></a>
            <a href="https://www.linkedin.com/home" className="linkedin"><i className="bx bxl-linkedin"></i></a>
          </div>
        </div>
  
        <nav id="navbar" className="nav-menu navbar">
          <ul>
            <li><a href="/" className="nav-link scrollto active"><i className="bx bx-home"></i> <span>Home</span></a></li>
            <li><a href="#about" className="nav-link scrollto"><i className="bx bx-user"></i> <span>About</span></a></li>
            <li><a href="#resume" className="nav-link scrollto"><i className="bx bx-file-blank"></i> <span>Resume</span></a></li>
            <li><a href="#skills" className="nav-link scrollto"><i className="bx bx-book-content"></i> <span>Skills</span></a></li>
            <li><a href="#contact" className="nav-link scrollto"><i className="bx bx-envelope"></i> <span>Contact</span></a></li>
          </ul>
        </nav>
      </div>
    </header>

    
  <section id="hero" className="d-flex flex-column justify-content-center align-items-center">
    <div className="hero-container" data-aos="fade-in">
      <h1>Jaydeep</h1>
      <p>I'm <span className="typed" data-typed-items="Designer, Developer, Freelancer, Photographer"></span></p>
    </div>
  </section>
    </div>
  )
}

export default Header
