import React from 'react'

function Contact() {
  return (
    <div id='main'>
    
    <section id="contact" className="contact">
      <div className="container">

        <div className="section-title">
          <h2>Contact</h2>
        
        </div>

        <div className="row" data-aos="fade-in">

          <div  className="col-lg-12 mt-5 mt-lg-0 d-flex align-items-stretch">
            <div className="info">
              <div className="address">
                <i className="bi bi-geo-alt"></i>
                <h4>Location:</h4>
                <p>Mayur Vihar phase 3 Delhi , India</p>
              </div>

              <div className="email">
                <i className="bi bi-envelope"></i>
                <h4>Email:</h4>
                <p>jaydeepyadav.jcmst@gmail.com</p>
              </div>

              <div className="phone">
                <i className="bi bi-phone"></i>
                <h4>Call:</h4>
                <p>+91 8077115322</p>
              </div>

              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28021.392207208784!2d77.30969873476563!3d28.609554399999986!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce4df9ed3ea47%3A0x17130e6bce42ec01!2sMayur%20Vihar%20Phase%20III%2C%20New%20Delhi%2C%20Delhi!5e0!3m2!1sen!2sin!4v1732520390378!5m2!1sen!2sin" frameBorder={0} style={{border:0, width: "100%", height: "290px"}} allowFullScreen></iframe>

              {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d28021.392207208784!2d77.30969873476563!3d28.609554399999986!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1732520144512!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
             
            </div>

          </div>

          {/* <div className="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch">
            <form action="forms/contact.php" method="post" role="form" className="php-email-form">
              <div className="row">
                <div className="form-group col-md-6">
                  <label htmlFor="name">Your Name</label>
                  <input type="text" name="name" className="form-control" id="name" required/>
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="name">Your Email</label>
                  <input type="email" className="form-control" name="email" id="email" required/>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="name">Subject</label>
                <input type="text" className="form-control" name="subject" id="subject" required/>
              </div>
              <div className="form-group">
                <label htmlFor="name">Message</label>
                <textarea className="form-control" name="message" rows="10" required></textarea>
              </div>
              <div className="my-3">
                <div className="loading">Loading</div>
                <div className="error-message"></div>
                <div className="sent-message">Your message has been sent. Thank you!</div>
              </div>
              <div className="text-center"><button type="submit">Send Message</button></div>
            </form>
          </div> */}

        </div>

      </div>
    </section>
    </div>
  )
}

export default Contact
