import React from 'react'

function Resume() {
  return (
    <div id='main'>
  
    <section id="resume" className="resume">
      <div className="container">

        <div className="section-title">
          <h2>Resume</h2>
          <p>I want to seek challenging assignments and responsibility
          with an opportunity for growth and career advancement
          as a successfully achievement. Enthusiastic and motivated Computer Science graduate with a strong foundation in web development and a passion for building dynamic user interfaces using React JS. Seeking an entry-level position to apply my skills and contribute to innovative projects.</p>
        </div>

        <div className="row">
          <div className="col-lg-6" data-aos="fade-up">
            <h3 className="resume-title">Projects</h3>
            <div className="resume-item pb-0">
              <h4>Personal Portfolio Website</h4>
              <p><em></em></p>
              <ul>
                <li>Developed a responsive portfolio website using React to showcase my porjects and skills.</li>
                <li>Implemented React Router for navigation and used CSS for styling.</li>
              </ul>
            </div>

            
            <div className="resume-item">
              <h4>E-Commerce Product Page</h4>
              
              <p><em></em></p>
              <ul>
                <li>Created a dynamic product page using React and Redux for state management.
                </li>
                <li>Integrated mock API to fetch product data and display it in a user-friendly format.               
                 </li>
                
              </ul>
            </div>
            
            <div className="resume-item">
              <h4>To-Do List Application</h4>
              
              <p><em></em></p>
              <ul>
                <li>Built a simple to-do list application using React, allowing users to add, delete, edit, update and mark tasks as complete.
                </li>
                <li>Utilized local storage to persist user data.               
                 </li>
                 <li>Each task can have a title, description, and due date.
                 </li>
              </ul>
            </div>
            

            



           
          </div>
          <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">
          <div className="resume-item">
            <h4>INTERNSHIP</h4>
            <h5>01/2022-05/2022</h5>
            <p><em>Web Innovation, Bareilly</em></p>
            <ul>
              <li>Assess the user needs.
              </li>
              <li>Write and test code, refining and rewriting it as
              necessary and communicate with any
              programmers involved in the project.              
               </li>
              <li>Research, design and write new software
              programs.</li>
              <li>Collaborate with designers and back-end
              developers to implement user interfaces</li>
              <li>Test and debug code to ensure cross-browser
              compatibility and optimal performance.
              </li>
              <li>Assist in the development of new features and
              functionality for web applications</li>
            </ul>
          </div>
          <h3 className="resume-title">Education</h3>
          <div className="resume-item">
            <h4>MASTER OF COMPUTER APPLICATION</h4>
            <h5>2017 - 2019</h5>
            <p><em>AKTU University</em></p>
          </div>
          <div className="resume-item">
            <h4>BACHELOR OF COMPUTER APPLICATION</h4>
            <h5>2014 - 2017</h5>
            <p><em>MJPRU University</em></p>
           
          </div>
          <div className="resume-item">
            <h4>HIGHER SECONDARY SCHOOL SCIENCE
            EDUCATION</h4>
            <h5>2010 - 2014</h5>
            <p><em>KDEMIC , BAREILLY 2013 - 2014</em></p>
            
          </div>
            
          </div>
        </div>

      </div>
    </section>
    </div>
  )
}

export default Resume
