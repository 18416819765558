import logo from './logo.svg';
// import './App.css';

import Header from './Component/Header';
import About from './Component/About';
import FactSection from './Component/FactSection';

import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Skills from './Component/Skills';
import Resume from './Component/Resume';
import Portfolio from './Component/Portfolio';
import Service from './Component/Service';
import Testimonial from './Component/Testimonial';
import Contact from './Component/Contact';
import Footer from './Component/Footer';
import { BrowserRouter,Routes,Route } from 'react-router-dom';
import Portfolio_detail from './Component/Portfolio_detail';

function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  
  return (
    <div className="App">
    <BrowserRouter>
     <Header></Header>
     <About></About>
    {/*<FactSection></FactSection>*/}
     <Skills></Skills>
     <Resume></Resume>
     {/* <Portfolio></Portfolio> */}
      {/* <Service></Service>*/}
     {/* <Testimonial></Testimonial> */}
     <Contact></Contact>
     <Footer></Footer>
     <Routes>
     <Route path='/portfolio-details' element={<Portfolio_detail></Portfolio_detail>}/>
     </Routes>
     </BrowserRouter>
    </div>
  );
}

export default App;
